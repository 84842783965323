<template>
  <div>
    <van-cell-group v-for="item in list" :key="item + 'InDocL'">
      <van-cell style="margin-top:3%; float:left;" @click="onclickbtn(item.docName)">
        <template slot="icon">
          <van-image width="4em" height="4em" fit="contain" :src="item.logo" alt="证件照" />
          <!-- <img width="60px" height="84px" fit="contain" :id="'DocImg'+item.id" alt="证件照" /> -->
        </template>
        <template slot="title">
          <span style="margin-left:10px; color:#57c4b7;">{{item.doc}}</span>
          <span style="float:right;color: #969799;">科室：{{item.dep}}</span>
        </template>
        <!-- <template slot="default">
          <span style="float:right;color: #969799;">科室：{{item.dep}}</span>
        </template> -->
        <template slot="label">
          <span style="margin-left:10px;">职称：{{item.lev}}</span>
        </template>
      </van-cell>
    </van-cell-group>
    <div style="font-size: 12px; color: #D3D3D3; margin: 2% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import { Toast } from "vant";
import store from "../../store";
import AppConsts from "../../lib/appconst";
export default {
  data() {
    return {
      list: [],
      // logos: require('@/assets/FGlogo.png'),
      // logos: require('@/assets/JClogo.png'),
      logos: require("@/assets/Doc.png")
    };
  },
  created() {
    this.onload();
  },
  methods: {
    async onload() {
        let depname = store.getters["store/depname"];
            // Toast(depname);
      await ajax
        .get("/Api/QueryService/OutputDoctorInfo?input="+depname)
        .then(res => {
          if(res.data.result.length==0){
                Toast("未配置科室医生信息");
          }
          res.data.result = res.data.result.filter((item) => item.dept == depname);
          this.list = res.data.result;
          console.log(this.list);
          // let token = sessionStorage.getItem("authToken");
          for (let i = 0; i < this.list.length; i++) {
            let name = this.list[i].docName.split(".");
            console.log(name);
            this.list[i].dep = name[0];
            this.list[i].doc = name[1];
            this.list[i].lev = name[2];
            let http = this.list[i].key;
            this.logos = http === null || http === "" ? this.logos : "https://api.fgxrmyy.cn/api/Attachment/"+http;
            this.list[i].logo = this.logos;
            // let key = this.list[i].key;
            // var id = "DocImg" + this.list[i].id;
            // console.log(id);
            // this.getImg(key,id);
          }
          // console.log(this.list);
        })
        .catch(err => {
          Toast("错误：" + err.message);
        });
    },
    onclickbtn(docName) {
      // alert(doc);
      store.commit("store/setDorname", docName);
      this.$router
        .replace({
          path: "/InDocI"
        })
        .catch(err => {
          console.log(err);
        });
    },
    getImg(key,id) {
      let Url = AppConsts.remoteServiceBaseUrl;
      let token = sessionStorage.getItem("authToken");
      var url = Url + "api/Attachment/" + key;
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "blob"; //也可以使用POST方式，根据接口
      xhr.setRequestHeader(
        "Authorization",
        " Bearer "+token
      );
      xhr.onload = function(res) {
        if (this.status === 200) {
          console.log(res);
          var type = xhr.getResponseHeader("Content-Type");
          var blob = new Blob([this.response], { type: type });
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(blob);
          } else {
            var URL = window.URL || window.webkitURL;
            var objectUrl = URL.createObjectURL(blob);
            console.log(id);

            var img = document.getElementById(id);
            img.setAttribute("src", objectUrl);
            // this.logos = objectUrl;
          }
        } else {
          Toast("获取医生图片失败!");
        }
      };
      xhr.send();
    }
  }
};
</script>
<style>
</style>